.counter {
    width: 50%;
    margin: 0 auto 10px auto;
    height: 50px;
    position: relative;
}

.counter-img {
    position: absolute;
    width: 107px;
    top: -6px;
    right: -74px;
}

span[aria-hidden=true] {
    margin-right: 4px;
    box-shadow: 0 4px 7px -3px rgba(0,0,0,0.8);
}

.watt-suffixe {
    height: 50px;
    line-height: 50px;
    font-size: 29px;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    color: rgb(255, 255, 255);
    background: rgb(51, 51, 51);
    padding: 0 5px;
}
