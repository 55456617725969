.App-Battery-container .form-horizontal {
    min-height: 100px;
}

.App-Battery {
    display: flex;
    height: 38px;
    justify-content: space-between;
}

.App-Battery-form {
    padding: 1px 15px 15px 15px;
}

.App-Battery-form .helper {
    padding-right: 10px;
    padding-top: 4px;
}

.App-Battery-zipCode {
    width: 40%;
    display: flex;
}

.App-Battery-zipCode > .form-control {
    width: 62%;
}

.App-Battery-average {
    display: flex;
    align-items: center;
}

.App-Battery-average .form-control {
    width: 70px;
}

.App-Battery-appliance-total {
    align-items: end;
    margin-top: 10px !important;
}

.App-Battery-col {
    display: flex;
    align-items: center;
    margin-left: -7px;
}

.App-Battery-col label {
    padding-left: 30px;
}

.App-Battery-appliance {
    padding: 5px 0 15px 0;
    font-weight: bold;
    position: relative;
    background: #92d6e7;
    border-radius: 3px;
    margin-top: 30px !important;
}

.App-Battery-appliance-select {
    padding-right: 0;
    display: flex;
    align-items: flex-end;
}

.row>* {
    padding-right: 0 !important;
}

.App-Battery-appliance-trash {
    position: absolute;
    top: -19px;
    right: 0;
    cursor: pointer;
    width: 22px !important;
    height: 22px !important;
    background: #92d6e7;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px !important;
}

.App-Battery-appliance-electric svg {
    width: 43px;
    height: 43px;
}

.App-Battery-total-watt {
    font-weight: 900;
}

.App-Battery-col-count {
    max-width: 70px !important;
    padding-left: 3px !important;
}

.App-Battery-col-count > label, .App-Battery-col-watt > label {
    font-size: 12px;
}

.App-Battery-col-watt {
    padding-left: 3px !important;
    max-width: 100px !important;
}

@media (max-width: 800px) {
    .App-Battery-col-watt {
        max-width: 90px !important;
    }

    .App-Battery-container .form-horizontal {
        min-height: 130px;
    }

    .App-Battery {
        display: flex;
        height: 62px;
    }

    .App-Battery-zipCode {
        flex-direction: column;
    }

    .App-Battery-zipCode > .form-control {
        width: 100%;
    }

    .App-Battery-average {
        flex-direction: column-reverse;
    }

    .App-Battery-appliance-trash {
        /*right: -45%;*/
    }

    .App-Battery-appliance-select {
        box-sizing: content-box;
        margin-left: -18px !important;
    }

    .App-Battery-appliance-details {
        align-items: baseline;
        box-sizing: content-box;
        margin-left: -18px !important;
    }

    .App-Battery-appliance-details label{
        font-size: 11.5px;
    }

    .App-Battery-appliance-total button {
        font-size: 13px;
        height: 38px;
    }
}

