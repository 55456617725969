.App-result {
    margin-bottom: 15px;
    padding-bottom: 20px;
}

.App-result-low {
    font-size: 16px;
    text-align: left;
    display: inline-block;
}

p.note-result {
    font-size: 12px;
}

.results {
    background: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    padding: 1rem;
    color: #6c757d;
    box-shadow: 0 6px 11px -6px rgba(0,0,0,0.3);
    margin: 20px 0;
}

.results .line-results b {
    font-size: 33px;
}

table.result-inverter-table {
    width: 100%;
    text-align: left;
}

table.result-inverter-table tr {
    border: solid;
    border-width: 1px 0;
    border-color: rgba(0,0,0,0.2);
}

table.result-inverter-table tr:first-child {
    border-top: none;
}
table.result-inverter-table tr:last-child {
    border-bottom: none;
}

table.result-inverter-table tr td {
    padding: 20px 0;
}

.result-inverter-table .result-amazon-link {
    display: flex;
    flex-direction: column;
}

.result-amazon-link button {
    width: 100%;
}
